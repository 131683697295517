import { ArrowForwardTwoTone } from '@mui/icons-material';
import { NextSeo } from 'next-seo';
import React from 'react';

import imgHikingSrc from '@headway/shared/assets/img/illustrations/Hiking.jpg';
import {
  CopyBody,
  CopyFooter,
  CopyTitle,
  Section,
  SectionContainer,
} from '@headway/ui/landing';

import { ButtonLink } from '../components/ButtonLink';
import { Image } from '../components/Image/Image';
import { Link } from '../components/Link';
import { PageWrapper } from '../components/Nav/PageWrapper';

export default class IndexPage extends React.Component {
  render() {
    return (
      <PageWrapper>
        <NextSeo
          title="Error Page"
          description="Headway page not found."
          openGraph={{}} // empty object currently needed due to https://github.com/garmeeh/next-seo/issues/544
        />
        <SectionContainer>
          <Section>
            <div
              css={{
                marginLeft: 400,
                marginRight: 400,
                marginBottom: 50,
              }}
            >
              <Image
                src={imgHikingSrc}
                alt="Tea time"
                sizes="(max-width: 768px) 375px, 850px"
              />
            </div>
          </Section>
          <Section centered={true}>
            <CopyTitle>Page not found</CopyTitle>
            <CopyBody>
              <p>Sorry, the page you were looking for could not be found.</p>
              <p>
                Please check that the address is correct or you can return to
                our <Link href="/">home page</Link>.
              </p>
            </CopyBody>
            <CopyFooter>
              <ButtonLink
                color="primary"
                variant="contained"
                size="large"
                endIcon={<ArrowForwardTwoTone />}
                href="/"
              >
                Take me home
              </ButtonLink>
            </CopyFooter>
          </Section>
        </SectionContainer>
      </PageWrapper>
    );
  }
}
